import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../service/axios';
import { toast } from 'react-toastify';

export const getProjectOwner = createAsyncThunk('projectOwnerSlice/getProjectOwner', async (data, { dispatch }) => {
    try {
        let response = await axios.get('/project-owner', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: data?.loader
        });
        await dispatch(removeSelectedProjectOwner());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const createProjectOwner = createAsyncThunk('projectOwnerSlice/createProjectOwner', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/project-owner', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Owner added successfully.');
        await dispatch(getProjectOwner());
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const updateProjectOwner = createAsyncThunk('projectOwnerSlice/updateProjectOwner', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/project-owner/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Owner updated successfully.');
        await dispatch(getProjectOwner());
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);
        return rejectWithValue(err);
    }
});

export const deleteProjectOwner = createAsyncThunk('projectOwnerSlice/deleteProjectOwner', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/project-owner/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Owner deleted successfully.');
        await dispatch(getProjectOwner());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('projectOwnerSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/project-owner/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );
        toast.success(response?.data?.message || 'Status updated successfully !');
        await dispatch(getProjectOwner());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getProjectOwnerById = createAsyncThunk('projectOwnerSlice/getProjectOwnerById', async (id) => {
    try {
        let response = await axios.get('/project-owner/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getOwnerTypeOptions = createAsyncThunk('projectOwnerSlice/getOwnerTypeOptions', async () => {
    try {
        let response = await axios.get('/owner-type/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const getOwnerOptions = createAsyncThunk('projectOwnerSlice/getOwnerOptions', async () => {
    try {
        let response = await axios.get('/project-owner/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const projectOwnerSlice = createSlice({
    name: 'projectOwnerSlice',
    initialState: {
        data: [],
        options: [],
        selectedProjectOwner: {},
        ownerOptions: []
    },
    reducers: {
        removeSelectedProjectOwner: (state, action) => {
            state.selectedProjectOwner = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProjectOwner.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getOwnerTypeOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
        builder.addCase(getProjectOwnerById.fulfilled, (state, action) => {
            state.selectedProjectOwner = action?.payload;
            return;
        });
        builder.addCase(getOwnerOptions.fulfilled, (state, action) => {
            state.ownerOptions = action?.payload;
            return;
        });
    }
});

export const { removeSelectedProjectOwner } = projectOwnerSlice.actions;
export default projectOwnerSlice.reducer;
