import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../../../../../service/axios';
import { toast } from 'react-toastify';

export const getSchAnnex2 = createAsyncThunk('schAnnex2Slice/getSchAnnex2', async (id) => {
    try {
        let response = await axios.post('/sch-annex-2/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const createSchAnnex2 = createAsyncThunk('schAnnex2Slice/createSchAnnex2', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/sch-annex-2', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Schedule Annex 2 added successfully.');
        await dispatch(getSchAnnex2(data?.projectId));

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);
        return rejectWithValue(err);
    }
});

export const updateSchAnnex2 = createAsyncThunk('schAnnex2Slice/updateSchAnnex2', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/sch-annex-2/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Schedule Annex 2 updated successfully.');
        dispatch(getSchAnnex2(data?.data?.projectId));

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});
export const deleteSchAnnex2 = createAsyncThunk('schAnnex2Slice/deleteSchAnnex2', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/sch-annex-2/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Schedule Annex 2 deleted successfully.');
        // await dispatch(getSchAnnex2());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const schAnnex2Slice = createSlice({
    name: 'schAnnex2Slice',
    initialState: {
        data: []
    },

    extraReducers: (builder) => {
        builder.addCase(getSchAnnex2.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default schAnnex2Slice.reducer;
