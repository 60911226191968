import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../../../../../service/axios';
import { toast } from 'react-toastify';

export const getSchAnnex1 = createAsyncThunk('schAnnex1Slice/getSchAnnex1', async (id) => {
    try {
        let response = await axios.post('/sch-annex-1/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const createSchAnnex1 = createAsyncThunk('schAnnex1Slice/createSchAnnex1', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/sch-annex-1', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Schedule Annex 1 added successfully.');
        await dispatch(getSchAnnex1(data?.projectId));

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);
        return rejectWithValue(err);
    }
});

export const updateSchAnnex1 = createAsyncThunk('schAnnex1Slice/updateSchAnnex1', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/sch-annex-1/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Schedule Annex 1 updated successfully.');
        dispatch(getSchAnnex1(data?.data?.projectId));

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});
export const deleteSchAnnex1 = createAsyncThunk('schAnnex1Slice/deleteSchAnnex1', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/sch-annex-1/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Schedule Annex 1 deleted successfully.');
        // await dispatch(getSchAnnex1());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const schAnnex1Slice = createSlice({
    name: 'schAnnex1Slice',
    initialState: {
        data: []
    },

    extraReducers: (builder) => {
        builder.addCase(getSchAnnex1.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default schAnnex1Slice.reducer;
