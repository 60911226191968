import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';

export const getRoadType = createAsyncThunk('roadTypeSlice/getRoadType', async (data) => {
    try {
        let response = await axios.get('/road-type', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: data?.loader
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createRoadType = createAsyncThunk('roadTypeSlice/createRoadType', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/road-type', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Road Type added successfully.');
        await dispatch(getRoadType());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const updateRoadType = createAsyncThunk('roadTypeSlice/updateRoadType', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/road-type/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Road Type updated successfully.');
        dispatch(getRoadType());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const deleteRoadType = createAsyncThunk('roadTypeSlice/deleteRoadType', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/road-type/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Road Type deleted successfully.');
        await dispatch(getRoadType());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('subCategorySlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/road-type/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getRoadType());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getRoadTypeOptions = createAsyncThunk('projectSlice/getRoadTypeOptions', async () => {
    try {
        let response = await axios.get('/road-type/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const roadTypeSlice = createSlice({
    name: 'roadTypeSlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getRoadType.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getRoadTypeOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default roadTypeSlice.reducer;
