import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../../service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

export const getRoad = createAsyncThunk('roadSlice/getRoad', async (id) => {
    try {
        const query = queryString.stringify(id);
        let response = await axios.get('/road' + `?${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const getRoadOptions = createAsyncThunk('projectOwnerSlice/getRoadOptions', async (id) => {
    try {
        const query = queryString.stringify(id);
        let response = await axios.get('/road/options' + `?${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const createRoad = createAsyncThunk('roadSlice/createRoad', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/road', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Road added successfully.');
        await dispatch(getRoad({ projectId: data?.projectId }));
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const deleteRoad = createAsyncThunk('roadSlice/deleteRoad', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/road/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Road deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateRoad = createAsyncThunk('roadSlice/updateRoad', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/road/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Road updated successfully.');
        await dispatch(getRoad({ projectId: data?.data?.projectId }));

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const updateStatus = createAsyncThunk('roadSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/road/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );

        toast.success(response?.data?.message || 'status updated successfully !');

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const roadSlice = createSlice({
    name: 'roadSlice',
    initialState: {
        data: [],
        options: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getRoad.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getRoadOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default roadSlice.reducer;
