import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';

export const getStructureType = createAsyncThunk('structureTypeSlice/getState', async () => {
    try {
        let response = await axios.get('/structure-type', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getStructureTypeOptions = createAsyncThunk('structureTypeSlice/getStructureTypeOptions', async () => {
    try {
        let response = await axios.get('/structure-type/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const createStructureType = createAsyncThunk(
    'structureTypeSlice/createStructureType',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.post('/structure-type', data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'StructureType added successfully.');
            dispatch(getStructureType());

            return Promise.resolve(response.data);
        } catch (err) {
            if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            }
            console.log('err', err);
            return rejectWithValue(err);
        }
    }
);

export const updateStructureType = createAsyncThunk(
    'structureTypeSlice/updateStructureType',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.put('/structure-type/' + data.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'StructureType updated successfully.');
            dispatch(getStructureType());

            return Promise.resolve(response.data);
        } catch (err) {
            if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            }
            console.log('err', err);

            return rejectWithValue(err);
        }
    }
);

export const deleteStructureType = createAsyncThunk('structureTypeSlice/deleteStructureType', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/structure-type/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'StructureType deleted successfully.');
        await dispatch(getStructureType());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('structureTypeSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/structure-type/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );
        await dispatch(getStructureType());
        toast.success(response?.data?.message || 'status updated successfully !');

        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});
export const structureTypeSlice = createSlice({
    name: 'structureTypeSlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getStructureType.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getStructureTypeOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default structureTypeSlice.reducer;
