import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import queryString from 'query-string';
import { toast } from 'react-toastify';

export const getMaterial = createAsyncThunk('materialSlice/getMaterial', async () => {
    try {
        let response = await axios.get('/material', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const createMaterial = createAsyncThunk('materialSlice/createMaterial', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/material', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Material added successfully.');
        await dispatch(getMaterial());
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const updateMaterial = createAsyncThunk('materialSlice/updateMaterial', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/material/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Material updated successfully.');
        dispatch(getMaterial());
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const deleteMaterial = createAsyncThunk('materialSlice/deleteMaterial', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/material/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Material deleted successfully.');
        await dispatch(getMaterial());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const updateStatus = createAsyncThunk('materialSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/material/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getMaterial());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const getMaterialOptions = createAsyncThunk('materialSlice/getMaterialOptions', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/material/options' + `?${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const materialSlice = createSlice({
    name: 'materialSlice',
    initialState: {
        data: [],
        option: []
    },

    extraReducers: (builder) => {
        builder.addCase(getMaterial.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });

        builder.addCase(getMaterialOptions.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
    }
});

export default materialSlice.reducer;
