import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';
import showLoader from '@components/loader';

export const getSupplier = createAsyncThunk('supplierSlice/getSupplier', async (data) => {
    try {
        let response = await axios.get('/supplier', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: data?.loader
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const createSupplier = createAsyncThunk('supplierSlice/createSupplier', async (data, { dispatch, rejectWithValue }) => {
    try {
        showLoader(true);
        let response = await axios.post('/supplier', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Supplier added successfully.');
        await dispatch(getSupplier());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);
        return rejectWithValue(err);
    }
});

export const updateSupplier = createAsyncThunk('supplierSlice/updateSupplier', async (data, { dispatch, rejectWithValue }) => {
    try {
        showLoader(true);
        let response = await axios.put('/supplier/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Supplier updated successfully.');
        dispatch(getSupplier());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const deleteSupplier = createAsyncThunk('supplierSlice/deleteSupplier', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/supplier/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Supplier deleted successfully.');
        await dispatch(getSupplier());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateSupplierStatus = createAsyncThunk('supplierSlice/updateSupplierStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/supplier/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getSupplier());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getSupplierOptions = createAsyncThunk('supplierSlice/getSupplierOptions', async (id) => {
    try {
        let response = await axios.get('/supplier/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const supplierSlice = createSlice({
    name: 'supplierSlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getSupplier.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getSupplierOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default supplierSlice.reducer;
