import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

export const getItem = createAsyncThunk('itemSlice/getItem', async () => {
    try {
        let response = await axios.get('/item', {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const createItem = createAsyncThunk('itemSlice/createItem', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/item', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Item added successfully.');
        dispatch(getItem());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);
        return rejectWithValue(err);
    }
});

export const updateItem = createAsyncThunk('itemSlice/updateItem', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/item/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Item updated successfully.');
        dispatch(getItem());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);
        return rejectWithValue(err);
    }
});

export const deleteItem = createAsyncThunk('itemSlice/deleteItem', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/item/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Item deleted successfully.');
        await dispatch(getItem());

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('itemSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/item/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getItem());

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const getItemOptions = createAsyncThunk('itemSlice/getItemOptions', async (id) => {
    try {
        let response = await axios.get('/item/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const getItemTypeOptions = createAsyncThunk('itemSlice/getItemTypeOptions', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/item-type/options' + `?${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const itemSlice = createSlice({
    name: 'itemSlice',
    initialState: {
        data: [],
        unitOption: [],
        itemOption: []
    },

    extraReducers: (builder) => {
        builder.addCase(getItem.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getItemOptions.fulfilled, (state, action) => {
            state.itemOption = action?.payload;
            return;
        });
    }
});

export default itemSlice.reducer;
