// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// auth config import
import authConfig from '@src/auth/config.js';

// Axios Imports
import axios from '@src/service/axios';

// Loader Components
import showLoader from '@components/loader';

// secure ls
import { secureGet, secureSet } from '@src/service/secureLs';

// toast
import { toast } from 'react-toastify';

const initialUser = () => {
    // const item = window.localStorage.getItem(authConfig.storageUserDataKeyName);
    const item = secureGet(authConfig.storageUserDataKeyName);
    //** Parse stored json or if none return initialValue
    return item;
};

const getAccessToken = () => {
    const item = window.localStorage.getItem(authConfig.storageTokenKeyName);
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : {};
};

export const verifyToken = createAsyncThunk('authentication/verifyToken', async () => {
    try {
        showLoader(true);
        const res = await axios.get('/token', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return res.data;
    } catch (err) {
        showLoader(false);
        // toast.error('Session Expired!');
        return false;
    }
});

export const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        userData: initialUser(),
        accessToken: getAccessToken()
    },
    reducers: {
        changeUserData: (state, action) => {
            state[authConfig.storageUserDataKeyName] = action.payload.userData;
            // localStorage.setItem(authConfig.storageUserDataKeyName, JSON.stringify(action.payload.userData));
            secureSet(authConfig.storageUserDataKeyName, action.payload.userData);
        },
        changeOriginToken: (state, action) => {
            const originalAccessToken = window.localStorage.getItem(authConfig.storageTokenKeyName);
            state[authConfig.storageMockAdminKeyName] = originalAccessToken;
            localStorage.setItem(authConfig.storageMockAdminKeyName, originalAccessToken);
        },
        changeOriginReplaceToken: (state, action) => {
            const originalAccessToken = window.localStorage.getItem(authConfig.storageMockAdminKeyName);
            state[authConfig.storageTokenKeyName] = originalAccessToken;
            localStorage.setItem(authConfig.storageTokenKeyName, originalAccessToken);
            localStorage.removeItem(authConfig.storageMockAdminKeyName);
        },
        changeAccessToken: (state, action) => {
            state[authConfig.storageTokenKeyName] = action.payload.accessToken;
            localStorage.setItem(authConfig.storageTokenKeyName, JSON.stringify(action.payload.accessToken));
        },
        handleLogin: (state, action) => {
            state[authConfig.storageUserDataKeyName] = action.payload.userData;
            // localStorage.setItem(authConfig.storageUserDataKeyName, JSON.stringify(action.payload.userData));
            secureSet(authConfig.storageUserDataKeyName, action.payload.userData);

            state[authConfig.storageTokenKeyName] = action.payload.accessToken;
            localStorage.setItem(authConfig.storageTokenKeyName, JSON.stringify(action.payload.accessToken));
        },
        handleLogout: (state) => {
            state.userData = {};
            state[authConfig.storageTokenKeyName] = null;
            state[authConfig.storageUserDataKeyName] = null;

            // ** Remove user, accessToken from localStorage
            localStorage.removeItem(authConfig.storageProjectSettingKeyName);
            localStorage.removeItem(authConfig.storageUserDataKeyName);
            localStorage.removeItem(authConfig.storageTokenKeyName);
            localStorage.removeItem(authConfig.storagePermissionKeyName);
        }
    },

    extraReducers: (builder) => {
        builder.addCase(verifyToken.fulfilled, (state, action) => {
            if (action.payload) {
                state[authConfig.storageUserDataKeyName] = action.payload.userData;
                // localStorage.setItem(authConfig.storageUserDataKeyName, JSON.stringify(action.payload.userData));
                secureSet(authConfig.storageUserDataKeyName, action.payload.userData);
            }
        });
    }
});

export const { handleLogin, handleLogout, changeAccessToken, changeUserData, changeOriginToken, changeOriginReplaceToken } =
    authSlice.actions;

export default authSlice.reducer;
