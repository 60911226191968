import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getUnitMaster = createAsyncThunk('unitMasterSlice/getUnitMaster', async () => {
    try {
        let response = await axios.get('/unit', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const getUnitIdOptions = createAsyncThunk('unitMasterSlice/getUnitIdOptions', async (isActive) => {
    try {
        const urlParams = {
            isBaseUnit: isActive ? 0 : 1
        };
        let response = await axios.get('/unit/options', {
            params: urlParams,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const createUnitMaster = createAsyncThunk('unitMasterSlice/createUnitMaster', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/unit', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Unit added successfully.');
        await dispatch(getUnitMaster());
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const updateUnitMaster = createAsyncThunk('unitMasterSlice/updateUnitMaster', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/unit/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Unit updated successfully.');
        dispatch(getUnitMaster());
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const deleteUnitMaster = createAsyncThunk('unitMasterSlice/deleteUnitMaster', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/unit/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Unit deleted successfully.');
        await dispatch(getUnitMaster());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const updateStatus = createAsyncThunk('unitMasterSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/unit/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getUnitMaster());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const unitMasterSlice = createSlice({
    name: 'unitMasterSlice',
    initialState: {
        data: [],
        unitIdOptions: []
    },

    extraReducers: (builder) => {
        builder.addCase(getUnitMaster.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getUnitIdOptions.fulfilled, (state, action) => {
            state.unitIdOptions = action?.payload;
            return;
        });
    }
});

export default unitMasterSlice.reducer;
