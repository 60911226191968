import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';
import showLoader from '@components/loader';

export const getMaterialCategory = createAsyncThunk('materialCategorySlice/getMaterialCategory', async (data) => {
    try {
        let response = await axios.get('/material-category', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: data?.loader
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const createMaterialCategory = createAsyncThunk(
    'materialCategorySlice/createMaterialCategory',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            showLoader(true);
            let response = await axios.post('/material-category', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Material Category added successfully.');
            await dispatch(getMaterialCategory());
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            }
            console.log('err', err);

            return rejectWithValue(err);
        }
    }
);

export const updateMaterialCategory = createAsyncThunk(
    'materialCategorySlice/updateMaterialCategory',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            showLoader(true);
            let response = await axios.put('/material-category/' + data.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Material Category updated successfully.');
            dispatch(getMaterialCategory());
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            }
            console.log('err', err);

            return rejectWithValue(err);
        }
    }
);
export const deleteMaterialCategory = createAsyncThunk('materialCategorySlice/deleteMaterialCategory', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/material-category/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Material Category deleted successfully.');
        await dispatch(getMaterialCategory());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getMaterialCategoryOptions = createAsyncThunk('materialCategorySlice/getMaterialCategoryOptions', async (data) => {
    try {
        let response = await axios.get('/material-category/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const updateCategoryStatus = createAsyncThunk('materialCategorySlice/updateCategoryStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/material-category/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getMaterialCategory());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const materialCategorySlice = createSlice({
    name: 'materialCategorySlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getMaterialCategory.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getMaterialCategoryOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default materialCategorySlice.reducer;
