import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../../service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

export const getTCS = createAsyncThunk('tcsSlice/getTCS', async (id, { dispatch }) => {
    try {
        const query = queryString.stringify(id);
        let response = await axios.get('/tcs' + `?${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(removeSelectedData());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createTCS = createAsyncThunk('tcsSlice/createTCS', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/tcs', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'TCS added successfully.');
        await dispatch(getTCS({ projectId: data?.projectId }));
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);
        return rejectWithValue(err);
    }
});

export const deleteTCS = createAsyncThunk('tcsSlice/deleteTCS', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/tcs/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'TCS deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateTCS = createAsyncThunk('tcsSlice/updateTCS', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/tcs/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'TCS updated successfully.');
        await dispatch(getTCS({ projectId: data?.data?.projectId }));

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);
        return rejectWithValue(err);
    }
});

export const updateStatus = createAsyncThunk('tcsSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/tcs/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );

        toast.success(response?.data?.message || 'status updated successfully !');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getTCSById = createAsyncThunk('tcsSlice/getTCSById', async (id) => {
    try {
        let response = await axios.get('/tcs/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getTCSConstructionItemOptions = createAsyncThunk('tcsSlice/getTCSConstructionItemOptions', async () => {
    try {
        let response = await axios.get('/tcs-construction-item/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const tcsSlice = createSlice({
    name: 'tcsSlice',
    initialState: {
        data: [],
        options: [],
        selectedData: {}
    },
    reducers: {
        removeSelectedData: (state, action) => {
            state.selectedData = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTCS.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getTCSConstructionItemOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
        builder.addCase(getTCSById.fulfilled, (state, action) => {
            state.selectedData = action?.payload;
            return;
        });
    }
});

export const { removeSelectedData } = tcsSlice.actions;
export default tcsSlice.reducer;
